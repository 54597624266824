import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectFeatureFlags } from "../../features/parameters/ParametersPage.selector";
import { FeatureName } from "./useFeatureFlags";

export interface UseVpnConnectionCheckType {
	isVpnConnected: boolean;
}

export const useVpnConnectionCheck = (): UseVpnConnectionCheckType => {
	const [isVpnConnected, setIsVpnConnected] = useState(false);
	const features = useSelector(selectFeatureFlags);

	const gecoEndpoint = useMemo(() => {
		if (features) {
			return features[FeatureName.geco_endpoint];
		}
	}, [features]);

	useEffect(() => {
		const setVpnConnectionStatus = async (endpoint: string | undefined) => {
			try {
				if (endpoint) {
					// creating new instance to bypass interceptors
					// @ts-ignore
					const instance = axios.create({ baseURL: endpoint });
					const response = await instance.get("/health ");
					if (!response?.status) {
						// if a status is not returned, that means we couldn't reach the GECO backend
						// if we get a status, even a 500, it means we are connected to the vpn
						return setIsVpnConnected(false);
					}
					return setIsVpnConnected(true);
				}
			} catch (error) {
				console.error("Issue with Geco health");
			}
		};

		const interval = setInterval(() => {
			// @ts-ignore
			setVpnConnectionStatus(gecoEndpoint);
		}, 5000);

		return () => clearInterval(interval);
	}, [gecoEndpoint]);

	return {
		isVpnConnected,
	};
};
