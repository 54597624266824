import { Formik, type FormikProps } from "formik";

import Counterparty from "./CounterPartyFields";
import {
	ContractDetailType,
	ErrorType,
} from "../../../requests_geco/contractsApi/contractsApi.types";
import {
	CounterpartyFormik,
	counterpartyTabFormikInitialValueMapper,
} from "../../contract_validation/formik/counterpartyFormik";
import {
	useLazyGetPartyDetailQuery,
	useLazySearchPartiesQuery,
} from "../../../requests_geco/gepoTprProxyApi";
import { Autocomplete, TextField } from "@mui/material";
import { useRtkQueryDynamicEndpoint } from "../../../common/hooks/useRtkQueryDynamicEndpoint";
import { useGetBooksQuery } from "../../../requests_geco/referentialApi/referentialApi";
import { useMemo } from "react";

export interface CounterpartyFormProps {
	formRef: React.Ref<FormikProps<CounterpartyFormik>>;
	contract: ContractDetailType | undefined;
	onSubmit: (data: CounterpartyFormik) => void;
}

const CounterpartyForm = ({
	formRef,
	contract,
	onSubmit,
}: CounterpartyFormProps) => {
	const [
		getPartyDetail,
		{
			data: detailParty,
			isLoading: isDetailLoading,
			error: detailPartyError,
		},
	] = useLazyGetPartyDetailQuery();
	const [searchParties, { data: parties, isLoading: isSearchLoading }] =
		useLazySearchPartiesQuery();


	const { data: books, isLoading: isBookLoading } =
		useRtkQueryDynamicEndpoint(useGetBooksQuery)({});


	const booksOptions = useMemo(() => {
			return (books|| []).map((book) => book.name);
		}, [books]); 

	return (
		<Formik
			validateOnChange={false}
			validateOnBlur={false}
			enableReinitialize
			initialValues={counterpartyTabFormikInitialValueMapper(contract)}
			onSubmit={onSubmit}
			innerRef={formRef}
		>
			{({
				handleChange,
				setFieldValue,
				handleSubmit,
				values,
			}) => (
				<form onSubmit={handleSubmit} id="counterPartyForm">
					
						<Counterparty
							values={values}
							parties={parties?.partyResults || []}
							detailParty={detailParty}
							errorMessage={detailPartyError as ErrorType}
							isLoading={isDetailLoading || isSearchLoading}
							setFieldValue={setFieldValue}
							handleChange={handleChange}
							searchParties={searchParties}
							getPartyDetail={getPartyDetail}
							label="Counterpart"
						/>
						<Autocomplete
							sx={{width:"100%"}}
							loading={isBookLoading}
							options={booksOptions}
							onInputChange={(_, inputValue) => {
								setFieldValue("mirror_book",inputValue)
							}}
							defaultValue={values.mirror_book}
							inputValue={values.mirror_book}
							renderInput={(params) => <TextField name="mirror_book" {...params} label="Select mirror book" />}
							/>
				</form>
			)}
		</Formik>
	);
};

export default CounterpartyForm;
