import React, { forwardRef, memo, type ReactNode, useEffect } from "react";

import { autoScrollWindowForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";

import { useBoardContext } from "./board-context";
import { Box } from "@mui/material";

type BoardProps = {
	children: ReactNode;
};

const boardStyles = {
	display: "-webkit-box",
	gap: "10px",
	margin: "0 10px",
};

const Board = forwardRef<HTMLDivElement, BoardProps>(
	({ children }: BoardProps, ref) => {
		const { instanceId } = useBoardContext();

		useEffect(() => {
			return autoScrollWindowForElements({
				canScroll: ({ source }) =>
					source.data.instanceId === instanceId,
			});
		}, [instanceId]);

		return (
			<Box sx={boardStyles} ref={ref}>
				{children}
			</Box>
		);
	}
);

Board.displayName = "Board";

export default memo(Board);
