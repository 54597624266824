import React, { useMemo, ReactNode } from "react";
import { Box, DialogActions, CircularProgress } from "@mui/material";
import { Formik } from "formik";
import { PrimaryButton } from "../../../../../common/components/CustomButton";
import { FluidButton } from "../../../../../common/components/FluidButton";
import { If } from "../../../../../common/components/If";
import { noop } from "../../../../../common/utils/operations";
import { FluidNumber } from "../../../../../common/components/FluidNumberField";
import FluidSelect from "../../../../../common/components/FluidSelect";
import InfoBlock from "../../../../../common/components/InfoBlock/InfoBlock";
import {
	AveragingType,
	FixingMethod,
	SettlementType,
} from "../../../../../requests_geco/contractsApi/contractsApi.types";
import { NegativePriceIndex } from "../../../../../requests_geco/referentialApi/referentialApi.types";
import {
	indexSettlementValidator,
	fixPriceSettlementValidator,
} from "../../../formik/editSettlementValidationValidator";
import { borderNeutralSecondary } from "../../../../../core/theme";
import { Spacer } from "../../../../../common/components/Spacer";
import { FluidTextField } from "../../../../../common/components/FluidTextField";
import { FeesComponent } from "./FeesComponent";
import { NpqrsField } from "./NpqrsField";

export interface BaseEditableSettlementCard {
	settlement: SettlementType;
	isLoading: boolean;
	indexes: NegativePriceIndex[];
	validationSchema?: any | (() => any);
	onSubmit: (formikSettlement: SettlementType) => void;
	getAdditionalFields?: (props: {
		values: any;
		errors: any;
		setFieldValue: (name: string, value: any) => void;
		handleChange: (event: any) => void;
	}) => ReactNode;
}

const style = {
	card: {
		width: "500px",
		minHeight: "380px",
		padding: "16px 24px",
		border: `1px solid ${borderNeutralSecondary}`,
		backgroundColor: "white",
	},
	container: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		gap: "24px",
	},
	submitWrapper: {
		marginLeft: "auto",
	},
};

export function EditableSettlementCardBase({
	isLoading,
	settlement,
	indexes,
	onSubmit,
	getAdditionalFields = (_) => {
		return <React.Fragment></React.Fragment>;
	},
	validationSchema = settlement?.index
		? indexSettlementValidator
		: fixPriceSettlementValidator,
}: BaseEditableSettlementCard) {
	const availableindexOptions = useMemo(() => {
		return (indexes || []).map((index: NegativePriceIndex) => ({
			value: `${index.orchestrade_id}`,
			label: index.name,
		}));
	}, [indexes]);

	return (
		<Box sx={style.card}>
			<Formik
				validateOnChange={false}
				validateOnBlur={false}
				validationSchema={validationSchema}
				enableReinitialize
				initialValues={settlement}
				onSubmit={onSubmit}
			>
				{({
					handleChange,
					handleSubmit,
					setFieldValue,
					resetForm,
					values,
					errors,
					dirty,
				}) => (
					<form onSubmit={handleSubmit}>
						<Box sx={style.container}>
							<If condition={values.index}>
								<InfoBlock
									info={[
										{
											label: "Index",
											value:
												(
													availableindexOptions || []
												).find(
													(index) =>
														index.value ===
														`${values.index?.commodity_fixing_id}`
												)?.label || "",
										},
										{
											label: "Type",
											value: settlement?.type || "",
										},
										{
											label: "Period",
											value: settlement?.period || "",
										},
									]}
								/>
							</If>
							<If condition={values.price}>
								<InfoBlock
									info={[
										{
											label: "Type",
											value: settlement?.type || "",
										},
										{
											label: "Period",
											value: settlement?.period || "",
										},
									]}
								/>
							</If>
							<If condition={values.index}>
								<FluidSelect
									sx={{ width: "60%" }}
									isLabelStatic
									items={availableindexOptions}
									label={"Index"}
									name={"index.commodity_fixing_id"}
									// @ts-ignore does not provides an HTMLElement as it should but the value itself
									onChange={(index: number) =>
										setFieldValue(
											"index.commodity_fixing_id",
											index
										)
									}
									value={`${values.index?.commodity_fixing_id}`}
									errorMessage={
										//@ts-ignore
										errors.index?.commodity_fixing_id
									}
								/>

								<FluidSelect
									sx={{ width: "60%" }}
									isLabelStatic
									items={[
										{
											value: FixingMethod.AVERAGING,
											label: "Averaging",
										},
										{
											value: FixingMethod.AVERAGING_NPQ,
											label: "Averaging NPQ",
										},
										{
											value: FixingMethod.AVERAGING_YEAR_TO_DATE,
											label: "Averaging Year to Date",
										},
									]}
									label={"Fixing Method"}
									name={"index.fixing_method"}
									value={`${values.index?.fixing_method}`}
									//@ts-ignore
									errorMessage={errors.index?.fixing_method}
									// @ts-ignore does not provides an HTMLElement as it should but the value itself
									onChange={(value: string) =>
										setFieldValue(
											"index.fixing_method",
											value
										)
									}
								/>
								<FluidSelect
									sx={{ width: "60%" }}
									isLabelStatic
									items={[
										{
											value: AveragingType.INDEX,
											label: "Monthly Average Price (Index)",
										},
										{
											value: AveragingType.DAILYWEIGHTED,
											label: "Daily Average Price (Daily Weighted)",
										},
										{
											value: AveragingType.WEIGHTED,
											label: "Hourly Average Price (Weighted)",
										},
									]}
									label={"Averaging Type"}
									name={"index.averaging_type"}
									value={`${values.index?.averaging_type}`}
									//@ts-ignore
									errorMessage={errors.index?.averaging_type}
									// @ts-ignore does not provides an HTMLElement as it should but the value itself
									onChange={(value: string) =>
										setFieldValue(
											"index.averaging_type",
											value
										)
									}
								/>
								<If
									condition={
										values.index?.fixing_method ===
										FixingMethod.AVERAGING_NPQ
									}
								>
									<NpqrsField
										settlementValues={values}
										//@ts-ignore
										errorMessage={errors?.index?.npqrs}
										name={"index.npqrs"}
										setFieldValue={setFieldValue}
									/>
									<FluidTextField
										value={values?.index?.ref_contract}
										label="Ref Contract"
										name={"index.ref_contract"}
										errorMessage={
											//@ts-ignore
											errors?.index?.ref_contract
										}
										onChange={handleChange}
									/>
								</If>
								<FluidNumber
									sx={{ width: "60%" }}
									onChange={handleChange}
									value={values?.index?.alpha?.commodity || 0}
									name={"index.alpha.commodity"}
									title={"Alpha"}
									errorMessage={
										//@ts-ignore
										errors?.index?.alpha?.commodity
									}
								/>

								<FeesComponent
									title={"Alpha Fees"}
									values={values}
									errors={errors}
									feeFieldName={"index.alpha.fees"}
									setFieldValue={setFieldValue}
									handleChange={handleChange}
								/>
								<FluidNumber
									sx={{ width: "60%" }}
									onChange={handleChange}
									value={values?.index?.beta?.commodity || 0}
									name={`index.beta.commodity`}
									title={"Beta"}
									errorMessage={
										//@ts-ignore
										errors?.index?.beta?.commodity
									}
								/>
								<FeesComponent
									title={"Beta Fees"}
									values={values}
									errors={errors}
									feeFieldName={"index.beta.fees"}
									setFieldValue={setFieldValue}
									handleChange={handleChange}
								/>
							</If>
							<If condition={values?.price}>
								<FluidNumber
									sx={{ width: "60%" }}
									onChange={handleChange}
									value={values?.price?.commodity || 0}
									name={`price.commodity`}
									title={"Fixed Price"}
									//@ts-ignore
									errorMessage={errors?.price?.commodity}
								/>
								<FeesComponent
									title={"Fees"}
									values={values}
									errors={errors}
									feeFieldName={"price.fees"}
									setFieldValue={setFieldValue}
									handleChange={handleChange}
								/>
							</If>

							{getAdditionalFields({
								values,
								errors,
								setFieldValue,
								handleChange,
							})}
						</Box>

						<Spacer gap={24} />
						<DialogActions>
							<If condition={!isLoading && dirty}>
								<PrimaryButton
									onClick={() => resetForm()}
									text="Reset"
									type="button"
									color="secondary"
								/>
								<FluidButton
									type="submit"
									label={"Save"}
									onClick={noop}
								/>
							</If>
							<If condition={!!isLoading}>
								<CircularProgress />
							</If>
						</DialogActions>
					</form>
				)}
			</Formik>
		</Box>
	);
}
