import { Box, IconButton, Menu, MenuItem, SxProps, Theme } from "@mui/material";
import { primaryColor } from "../../../../core/theme";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Tender } from "../../tender.module";
import { useNavigate } from "react-router-dom";
import { useTenderActions } from "../../hooks/useTenderActions";
import { useState } from "react";

interface TenderActionMenuProps {
	tender: Partial<Tender> | Tender;
}

const style: { [key: string]: SxProps<Theme> } = {
	menuItem: {
		"&:hover": {
			borderLeft: `2px ${primaryColor} solid`,
		},
	},
};

function TenderActionMenu(props: TenderActionMenuProps) {
	const { tender } = props;
	const navigate = useNavigate();
	const { tenderAction, tenderActionModal } = useTenderActions(
		tender as Tender
	);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const openMenu = Boolean(anchorEl);
	const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Box sx={{ display: "flex", justifyContent: "center" }}>
				<IconButton onClick={handleClickMenu}>
					<MoreVertIcon sx={{ color: primaryColor }} />
				</IconButton>
			</Box>
			<Menu
				id="tender-menu"
				aria-labelledby="tender-menu"
				anchorEl={anchorEl}
				open={openMenu}
				onClose={handleCloseMenu}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				PaperProps={{ style: { borderRadius: 0 } }}
			>
				{tender.actions_blotter?.map((action: any, key: number) => (
					<MenuItem
						key={action + key}
						sx={style.menuItem}
						component={action.redirection_url ? "a" : "li"}
						href={
							action.redirection_url ? action.redirection_url : ""
						}
						onClick={(event: any) => {
							event.preventDefault();
							if (action.redirection_url) {
								navigate(action.redirection_url);
								return false;
							}
							handleCloseMenu();
							tenderAction(action);
						}}
					>
						{action.display_name}
					</MenuItem>
				))}
			</Menu>
			{tenderActionModal}
		</>
	);
}

export default TenderActionMenu;
