import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	SxProps,
	Theme,
	Box,
	Typography,
	IconButton,
	Switch,
	FormControlLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { PrimaryButton } from "./CustomButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { borderGrey } from "../../core/theme";
import { Site, Portfolio } from "../../features/sites/sites.module";
import { SearchField } from "./SearchField";
import _ from "lodash";
import { enqueueSnackbar } from "notistack";
import GenericMultiSelect, { Item, SelectableItem } from "./GenericMultiSelect";
import { useGemforceSiteTableModel } from "../TableGenericModel/useGemforceSiteTableModel";
import { ColModel } from "./GenericTableRow";
import usePrevious from "../hooks/usePrevious";
import { initSites } from "../../features/sites/siteSlice";
import { useAppDispatch } from "../hooks/default";

const style: { [key: string]: SxProps<Theme> } = {
	searchInput: {
		minWidth: "325px",
		"& svg": {
			color: borderGrey,
		},
	},
	button: {
		marginRight: "16px",
		width: 106,
	},
};

interface GemforceSiteSelectionProps {
	open: boolean;
	isLoading: boolean;
	availableSites: (Site | Portfolio)[];
	title?: string;
	enableSearch?: boolean;
	enableSiteSelection?: boolean;
	currentSites: Site[];
	isSourcePortfolio: Boolean;
	getTprSite: (
		search: string,
		onlyPricingReady?: boolean,
		page?: number,
		sortBy?: string,
		order?: string
	) => void;
	onClose: (value: (Site | Portfolio)[]) => void;
	onUpdatePage: (number: number) => void;
	defaultSearch?: string;
	disabled?: boolean;
}

export default function GemforceSiteSelection({
	open,
	availableSites,
	title,
	isLoading,
	enableSearch = true,
	enableSiteSelection = true,
	currentSites,
	isSourcePortfolio,
	getTprSite,
	onClose,
	defaultSearch,
	disabled,
	...other
}: GemforceSiteSelectionProps) {
	const dispatch = useAppDispatch();
	const [selectedSites, setSelectedSites] = useState<(Site | Portfolio)[]>(
		[]
	);

	const [onlyPricingsReady, setOnlyPricingsReady] = useState(true);
	const previousOnlyPricingsReady = usePrevious(onlyPricingsReady);
	const [search, setSearch] = useState(defaultSearch);
	const [sortParam, setSortParam] = useState<{
		sortBy: string;
		order: "asc" | "desc";
	}>({
		sortBy: "",
		order: "asc",
	});
	const previousSearch = usePrevious(search);
	const [page, setPage] = useState(1);

	const handleCancel = () => {
		onClose(currentSites);
	};

	const handleOk = () => {
		onClose(selectedSites);
	};
	const isSitesWithMultipTechnoAdded = () =>
		!!isSourcePortfolio &&
		_.uniqBy(selectedSites, (site) => (site as Site)?.techno?.name).length >
			1;

	useEffect(() => {
		if (isSitesWithMultipTechnoAdded())
			enqueueSnackbar(
				`Adding multiple sites with different technologies is not allowed.`,
				{
					variant: "error",
					autoHideDuration: 3000,
				}
			);
	}, [selectedSites]);

	useEffect(() => {
		getTprSite(
			search || "",
			onlyPricingsReady,
			page,
			sortParam.sortBy,
			sortParam.order
		);
	}, [onlyPricingsReady, search, page]);

	useEffect(() => {
		dispatch(initSites());
		setPage(1);
		getTprSite(
			search || "",
			onlyPricingsReady,
			1,
			sortParam.sortBy,
			sortParam.order
		);
	}, [sortParam]);

	useEffect(() => {
		if (search != previousSearch) {
			setPage(1);
		}
	}, [previousSearch, search]);
	useEffect(() => {
		if (onlyPricingsReady != previousOnlyPricingsReady) {
			setPage(1);
		}
	}, [previousOnlyPricingsReady, onlyPricingsReady]);

	const rowModel = useGemforceSiteTableModel().map((m) => {
		return m as ColModel<Item<Site | Portfolio>>;
	});

	if (!open) {
		return <></>;
	}

	return (
		<Dialog
			PaperProps={{ style: { height: "80%" } }}
			sx={{ "& .MuiDialog-paper": { width: "90%" } }}
			fullWidth={true}
			maxWidth={false}
			open={open}
			{...other}
		>
			<DialogTitle>
				<Box
					sx={{
						display: "flex",
						p: 1,
						bgcolor: "background.paper",
						borderRadius: 1,
					}}
				>
					<Box sx={{ flexGrow: 1, alignSelf: "center" }}>
						<Typography
							paragraph
							sx={{ fontSize: "30px", marginBottom: "-10px" }}
						>
							{title ?? "Add Site"}
						</Typography>
					</Box>
					<IconButton onClick={() => handleCancel()}>
						<CloseIcon />
					</IconButton>
				</Box>
				<Box sx={{ display: "flex" }}>
					{enableSearch && (
						<SearchField
							onSearch={setSearch}
							defaultValue={defaultSearch}
						/>
					)}
					<FormControlLabel
						control={
							<Switch
								checked={onlyPricingsReady}
								onChange={() =>
									setOnlyPricingsReady((prev) => !prev)
								}
							/>
						}
						label="Only pricing ready"
					/>
				</Box>
			</DialogTitle>
			<DialogContent dividers>
				<GenericMultiSelect<Site | Portfolio>
					onSelectionChange={(sites: Item<Site | Portfolio>[]) => {
						setSelectedSites(
							sites.map((m) => m as Site | Portfolio)
						);
					}}
					isLoading={isLoading}
					initialSelectedItems={currentSites}
					itemList={(availableSites as Site[]) || []}
					isItemSelectable={(
						_item: SelectableItem<Site | Portfolio>
					) => !!(_item as Site).is_pricing_ready}
					leftRowModel={rowModel}
					rightRowModel={rowModel}
					keyProperty={(item: SelectableItem<Site | Portfolio>) =>
						(item as Site)?.asset_id
					}
					itemReadyProperty={(
						_item: SelectableItem<Site | Portfolio>
					) => !!(_item as Site).is_pricing_ready}
					withSearch={true}
					updatePage={setPage}
					updateSortParam={setSortParam}
					sortParam={sortParam}
					page={page}
				/>
			</DialogContent>
			<Box
				sx={{
					display: "flex",
					p: 1,
					bgcolor: "background.paper",
					borderRadius: 1,
				}}
			>
				<Box
					sx={{
						flexGrow: 1,
						alignSelf: "center",
						marginLeft: "20px",
						marginTop: "10px",
					}}
				>
					{enableSiteSelection && (
						<Typography paragraph>
							{`${selectedSites.length} site(s) selected`}
						</Typography>
					)}
				</Box>
				<DialogActions sx={{ marginRight: "10px" }}>
					<PrimaryButton
						text={enableSiteSelection ? "Add selection" : "Close"}
						type="button"
						color="primary"
						sx={{
							...style.button,
							width: 160,
						}}
						disabled={
							disabled ||
							(enableSiteSelection && !selectedSites.length) ||
							!!isSitesWithMultipTechnoAdded()
						}
						onClick={handleOk}
					>
						{enableSiteSelection && (
							<AddCircleIcon sx={{ color: "#fff" }} />
						)}
					</PrimaryButton>
				</DialogActions>
			</Box>
		</Dialog>
	);
}
