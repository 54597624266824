import { useEffect, useMemo } from "react";
import { Box } from "@mui/material";

import { ErrorType } from "../../../requests_geco/contractsApi/contractsApi.types";
import { formatApiErrorMessage } from "../../../common/utils/formatApiErrorMessage";
import CustomAutocomplete from "../../../common/components/CustomAutocomplete";
import { If } from "../../../common/components/If";
import { ErrorText } from "../../../common/components/ErrorText";

import {
	TprPartyDetailType,
	TprPartyListType,
} from "../../../requests_geco/gepoTprProxyApi/gepoTprProxyApi.types";

import type { CounterpartyFormik } from "../../contract_validation/formik/counterpartyFormik";

export interface CounterpartyProps {
	values: CounterpartyFormik;
	parties: TprPartyListType[];
	detailParty: TprPartyDetailType | undefined;
	errorMessage: ErrorType;
	isLoading: boolean;
	label?: string;
	name?:string;
	searchParties: ({ search }: { search: string }) => void;
	getPartyDetail: ({ id }: { id: string }) => void;
	setFieldValue: (field: string, value: any) => void;
	handleChange: (e: React.ChangeEvent<any> | boolean) => void;
}

const Counterparty = ({
	values,
	parties,
	detailParty,
	errorMessage,
	isLoading,
	setFieldValue,
	searchParties,
	getPartyDetail,
	name="party",
	label = "Counter Party",
}: CounterpartyProps) => {
	const partiesResult = useMemo(() => {
		return (parties || []).map((party: TprPartyListType) => ({
			...party,
			id: party.partyId,
		}));
	}, [parties]);

	useEffect(() => {
		if (detailParty) {
			setFieldValue("party", detailParty);
		}
	}, [detailParty]);

	const error = useMemo(() => {
		return errorMessage
			? formatApiErrorMessage(errorMessage as ErrorType)
			: null;
	}, [errorMessage]);

	return (
		<Box>
			<CustomAutocomplete
				fetchData={(search: string) => searchParties({ search })}
				data={partiesResult}
				// @ts-ignore
				setFieldValue={(_fieldName: string, value: TprPartyListType) =>
					getPartyDetail({ id: value.id! })
				}
				label={label}
				name={name}
				loading={isLoading}
				value={values.party}
			/>
			<If condition={error}>
				<ErrorText>{error}</ErrorText>
			</If>
		</Box>
	);
};

export default Counterparty;
