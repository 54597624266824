import { CompactTender, Tender } from "../tender.module";

function stringToColor(string: string) {
	let hash = 0;
	let i;

	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = "#";

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}

	return color;
}

export function stringAvatar(name: string) {
	return {
		sx: {
			bgcolor: stringToColor(name),
			width: "32px",
			height: "32px",
			fontSize: "16px",
		},
		children: `${name.split(" ")[0][0]}${
			name.split(" ")[1][0]
		}`.toUpperCase(),
	};
}

export function mapTenderToWorkflowView(tender: Tender): CompactTender {
	return {
		id: tender.id,
		name: tender.name,
		creation_date: tender.creation_date,
		country_iso2_code: tender.country_iso2_code,
		site_count: tender.site_count,
		direction: tender.direction,
		due_date: tender.due_date,
		trader: tender.trader,
		originator: tender.originator,
		actions_blotter: tender.actions_blotter,
	};
}
