import TableRowsIcon from "@mui/icons-material/TableRows";
import TableChartIcon from "@mui/icons-material/TableChart";
import { ToggleButtonGroup, ToggleButton, Paper } from "@mui/material";

import { toggleButtonGroupClasses } from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import { secondaryColor } from "../../../core/theme";
import { Dispatch, SetStateAction } from "react";

interface ViewToggleProps {
	activeView: string;
	setActiveView: Dispatch<SetStateAction<string>>;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }: any) => ({
	[`& .${toggleButtonGroupClasses.grouped}`]: {
		margin: theme.spacing(0.5),
		padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
		border: 0,
		backgroundColor: secondaryColor,
		[`&.Mui-selected`]: {
			backgroundColor: "white",
		},
	},
}));

function ViewToggle(props: ViewToggleProps) {
	const { activeView, setActiveView } = props;
	return (
		<Paper
			elevation={0}
			sx={(theme) => ({
				display: "flex",
				border: `1px solid ${theme.palette.divider}`,
				flexWrap: "wrap",
				backgroundColor: secondaryColor,
			})}
		>
			<StyledToggleButtonGroup
				size="small"
				value={activeView}
				exclusive
				onChange={(_, choice) => {
					setActiveView(choice);
				}}
				aria-label="text alignment"
			>
				<ToggleButton
					value="listView"
					aria-label="List View"
					sx={{ borderRadius: 0, textTransform: "none" }}
				>
					<TableRowsIcon />
					&ensp;List View
				</ToggleButton>
				<ToggleButton
					value="workflowView"
					aria-label="Workflow View"
					sx={{
						borderRadius: 0,
						textTransform: "none",
					}}
				>
					<TableChartIcon />
					&ensp;Workflow View
				</ToggleButton>
			</StyledToggleButtonGroup>
		</Paper>
	);
}

export default ViewToggle;
