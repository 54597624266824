import { Pricing, PricingStatus } from "./pricingListSlice";

export function pricingHasMargin(pricing: Pricing): boolean {
	return [
		PricingStatus.UNDER_OFFER,
		PricingStatus.LOST,
		PricingStatus.WON,
		PricingStatus.UNDER_BOOKING,
		PricingStatus.BOOKED,
		PricingStatus.BOOKING_FAILED,
		PricingStatus.FIXING_REQUESTED,
		PricingStatus.FIXED,
		PricingStatus.FIXED_APPROVED,
	].includes(pricing.status);
}
