import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { NJLink } from "@engie-group/fluid-design-system-react";
import { Box, Button, Chip, Tooltip, CircularProgress } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import EditIcon from "@mui/icons-material/Edit";

import PageTitle from "../../common/components/PageTitle/PageTitle";
import PageContent from "../../common/components/PageContent";
import { style } from "../contracts_page/ContractsPage.style";
import { useEffect, useMemo, useState } from "react";
import { Spacer } from "../../common/components/Spacer";
import InfoBlock from "../../common/components/InfoBlock/InfoBlock";
import { PrimaryButton } from "../../common/components/CustomButton";
import { useLazyGetOneContractQuery } from "../../requests_geco/contractsApi";
import SimpleTable, {
	HeadersType,
} from "../../common/components/SimpleTable/SimpleTable";
import {
	ContractDetailType,
	ContractPeriodType,
	ContractStatus,
	TradeType,
} from "../../requests_geco/contractsApi/contractsApi.types";
import YesNoTag from "../../common/components/YesNoTag";
import { ErrorText } from "../../common/components/ErrorText";
import {
	ErrorType,
	formatApiErrorMessage,
} from "../../common/utils/formatApiErrorMessage";
import { useRtkQueryDynamicEndpoint } from "../../common/hooks/useRtkQueryDynamicEndpoint";
import { If } from "../../common/components/If";
import { getPathWithParameters, PATH, setFilters } from "../../router-path";
import { FluidButton } from "../../common/components/FluidButton";
import { useIsUserWithinGroups } from "../../common/hooks/useIsUserWithinGroups";
import { Groups } from "../authentication/authentication.constant";
import { BreadCrumbs } from "../../common/components/BreadCrumbs";
import { useLazyGetOneContractChangeQuery } from "../../requests_geco/contractChangesApi";
import {
	getContractStatusText,
	getContractStatusColor,
	getValidationButtonText,
	getValidationButtonColor,
} from "../contract_validation/helpers";
import { ContactModal } from "./ContactModal";
import { CounterPartModal } from "./CounterPartModal";
import { MakeClickable } from "../../common/components/MakeClickable";

const ContractPeriodList = () => {
	const navigate = useNavigate();
	const { contractId } = useParams();

	const { isUserAuthorized } = useIsUserWithinGroups();

	const [isContactModalOpen, setContactModalOpen] = useState(false);
	const [selectedContract, setContractSelected] =
		useState<ContractDetailType | null>(null);

	const [
		getContractChangeProposition,
		{ isLoading: isGetContractChangeLoading },
	] = useLazyGetOneContractChangeQuery();

	const [
		getContractBase,
		{
			data: contract,

			isLoading: isGetContractLoading,
			error,
		},
	] = useLazyGetOneContractQuery();

	const getContract = useRtkQueryDynamicEndpoint(getContractBase);

	useEffect(() => {
		if (contractId) {
			getContract({
				contract_id: Number(contractId),
				skip_validation: false,
			});
			getContractChangeProposition(Number(contractId));
		}
	}, [contractId]);

	useEffect(() => {
		if (contractId && error) {
			getContract({
				contract_id: Number(contractId),
				skip_validation: true,
			});
		}
	}, [contractId, error]);

	const handleRowClick = (contractPeriodId: string | number) => {
		navigate(
			getPathWithParameters(PATH.CONTRACTPERIOD, {
				contractId,
				contractPeriodId,
			})
		);
	};

	const navigateToValidation = () => {
		navigate(
			getPathWithParameters(PATH.CONTRACT_VALIDATION, {
				contractId,
			})
		);
	};

	const navigateToCounterValidation = () => {
		navigate(
			getPathWithParameters(PATH.CONTRACT_COUNTER_VALIDATION, {
				contractId,
			})
		);
	};

	const headers: HeadersType<ContractPeriodType>[] = [
		{ label: "Status", accessor: "status" },
		{ label: "ID", accessor: "id" },
		{
			label: "Contract Period Name",
			accessor: "name",
			getAnchorTag: (item: ContractPeriodType) =>
				getPathWithParameters(PATH.CONTRACTPERIOD, {
					contractId,
					contractPeriodId: item.id,
				}),
		},
		{
			label: "Trade Date",
			accessor: "trade_date",
		},
		{
			label: "Start Date",
			accessor: "start_date",
		},
		{
			label: "End Date",
			accessor: "end_date",
		},
		{ label: "# Service point", accessor: "nb_service_point" },
		{ label: "# Site", accessor: "np_site" },
		{ label: "P50", accessor: "p50" },

		{
			label: "Processing Type",
			accessor: "processing_type",
		},
		{ label: "Deal Type", accessor: "deal_type.name" },
		{
			label: "Alpha",
			accessor: "ppa_physical.settlements[0].index.alpha.commodity",
			accessorOverride: (cp) =>
				`${
					cp.trade_type === TradeType.PPA_PHYSICAL
						? "ppa_physical"
						: "ppa_base_physical"
				}.settlements[0].index.alpha.commodity`,
		},
		{
			label: "Beta",
			accessor: "ppa_physical.settlements[0].index.beta.commodity",
			accessorOverride: (cp) =>
				`${
					cp.trade_type === TradeType.PPA_PHYSICAL
						? "ppa_physical"
						: "ppa_base_physical"
				}.settlements[0].index.beta.commodity`,
		},
		{
			label: "Fixed Price",
			accessor: "ppa_base_physical.settlements[0].price",
			accessorOverride: (cp) =>
				`${
					cp.trade_type === TradeType.PPA_PHYSICAL
						? "ppa_physical"
						: "ppa_base_physical"
				}.settlements[0].price.commodity`,
		},
		{
			label: "Test Phase",
			accessor: "test_phase",
		},
		{
			label: "Negative Condition",
			accessor: "negative_price",
		},
		{
			label: "Clickability",
			accessor: "clickability",
		},
		{
			label: "GOO Price",
			accessor: "goo_price",
		},
	];

	const contractStatus = useMemo(() => {
		return contract?.status;
	}, [contract]);

	const uniqueSortedItems = (items: { id: number; name: string }[]) => {
		const uniqueItems = _.uniqBy(items, "name");
		return _.sortBy(uniqueItems, "name");
	};

	const contractPeriods = useMemo(() => {
		return contract?.contract_periods.map((cp) => {
			const uniqueSortedServicePoints = uniqueSortedItems(
				cp.servicepoints
			);
			const uniqueSortedSites = uniqueSortedItems(cp.sites);

			return {
				...cp,
				clickability: <YesNoTag yes={!!cp.clickability} />,
				negative_price: <YesNoTag yes={!!cp.negative_price} />,
				nb_service_point: !!uniqueSortedServicePoints.length && (
					<Tooltip
						title={uniqueSortedServicePoints.map((sp) => (
							<p key={sp.name}>{sp.name}</p>
						))}
					>
						<Button sx={{ minWidth: 0 }}>
							{uniqueSortedServicePoints.length}
						</Button>
					</Tooltip>
				),
				np_site: !!uniqueSortedSites.length && (
					<Tooltip
						title={uniqueSortedSites.map((site) => (
							<p key={site.name}>{site.name}</p>
						))}
					>
						<Button
							sx={{ minWidth: 0 }}
							onClick={() =>
								navigate(
									PATH.SITES,
									setFilters({
										id__in: uniqueSortedSites.map(
											(site) => site.id
										),
									})
								)
							}
						>
							{uniqueSortedSites.length}
						</Button>
					</Tooltip>
				),
				ppa_physical: cp?.ppa_physical,
			};
		});
	}, [contract]);

	const contractUnderReview = useMemo(() => {
		if (contract) {
			return contract?.status !== ContractStatus.VALID;
		}
	}, [contract]);

	const contractUnderTraderValidation = useMemo(() => {
		return contract?.status === ContractStatus.UNDER_APPROVAL;
	}, [contract]);

	const breadCrumbsLabel = useMemo(() => {
		if (contract) {
			const contractPath = getPathWithParameters(PATH.CONTRACT_DETAIL, {
				contractId: contract.id,
			});

			return [{ path: contractPath, name: contract.name }];
		}
		return [];
	}, [contract]);

	const errorMessages = () => {
		if (!error) {
			return null;
		}

		return formatApiErrorMessage(error as ErrorType);
	};

	return (
		<>
			<PageTitle
				fontWeight={"bold"}
				label="Contract Period List"
				leftSide={
					<PrimaryButton
						text="Back"
						type="button"
						color="info"
						sx={{
							width: 106,
							color: "#171D21",
						}}
						onClick={() => navigate(PATH.CONTRACTS)}
					>
						<ChevronLeftIcon />
					</PrimaryButton>
				}
				hideRightSide={!contractStatus}
				rightSide={
					<Box sx={style.buttonGroup}>
						<If
							condition={
								contractUnderReview &&
								isUserAuthorized([Groups.geco_originator])
							}
						>
							<FluidButton
								onClick={navigateToValidation}
								label={getValidationButtonText(contractStatus)}
								type="button"
								variant={getValidationButtonColor(
									contractStatus
								)}
							></FluidButton>
						</If>
						<If
							condition={
								contractUnderTraderValidation &&
								isUserAuthorized([Groups.geco_trader])
							}
						>
							<FluidButton
								onClick={navigateToCounterValidation}
								label={"Review contract changes"}
								type="button"
								variant={getValidationButtonColor(
									contractStatus
								)}
							></FluidButton>
						</If>
					</Box>
				}
			/>
			{isGetContractLoading ||
				(isGetContractChangeLoading && (
					<Box sx={style.progressWrapper}>
						<CircularProgress data-testid="loader" />
					</Box>
				))}

			<PageContent>
				<>
					<BreadCrumbs crumbsLabels={breadCrumbsLabel} />
					<Spacer gap={16} />
					<If condition={contractStatus}>
						<Chip
							label={getContractStatusText(contractStatus)}
							sx={{
								background:
									getContractStatusColor(contractStatus),
								color: "white",
							}}
						/>
					</If>
					<Spacer gap={16} />
					{!isGetContractLoading &&
						!isGetContractChangeLoading &&
						error && (
							<Box>
								<Box sx={{ mb: 1 }}>
									Error Retrieving Contract
								</Box>
								<Box>
									{"We're"} sorry, but we encountered an issue
									while trying to retrieve the contract
									information.
								</Box>
								<Box>
									Please try again later. If the problem
									persists, contact support for assistance.
								</Box>
								<ErrorText>{errorMessages()}</ErrorText>
							</Box>
						)}
					{contract && contractPeriods && !isGetContractLoading && (
						<>
							<InfoBlock
								withBackground
								info={[
									{
										label: "Contract Type",
										value: contract?.agreement_type,
									},
									{
										label: "UID",
										value: contract?.contract_uid,
									},
									{
										label: "Signature Date",
										value: contract?.trade_date,
									},
									{
										label: "Start Date",
										value: contract?.start_date,
									},
									{
										label: "End Date",
										value: contract?.end_date,
									},
									{
										label: "Techno Type",
										value: contract?.techno_type,
									},
									{
										label: "Counterpart",
										value:
											contract?.mirror_book ??
											contract?.party?.mnemonic,
									},
									{ label: "Way", value: contract?.way },
									{
										label: "Country",
										value: contract?.country,
									},
									{
										label: "Book",
										value: contract?.trading_book,
									},
									{
										label: "Contacts",
										value: (
											<MakeClickable
												onClick={() =>
													setContactModalOpen(true)
												}
											>
												<NJLink href={"#"}>{`${
													(contract?.contacts || [])
														?.length
												} contacts`}</NJLink>
											</MakeClickable>
										),
									},
									{
										icon: (
											<Box
												sx={{
													margin: "auto",
												}}
											>
												<MakeClickable
													onClick={() =>
														setContractSelected(
															contract
														)
													}
												>
													<EditIcon color="primary" />
												</MakeClickable>
											</Box>
										),
									},
								]}
							/>
							<Spacer gap={24} />

							<SimpleTable
								headers={headers}
								items={contractPeriods}
								handleRowClick={handleRowClick}
							/>
						</>
					)}
				</>
			</PageContent>
			<ContactModal
				contacts={contract?.contacts || []}
				isOpen={isContactModalOpen}
				onClose={() => setContactModalOpen(false)}
			/>
			<CounterPartModal
				selectedContract={selectedContract}
				handleCLose={() => setContractSelected(null)}
			/>
		</>
	);
};

export default ContractPeriodList;
