import { RootState } from "../../core/rootReducers";
import { LoaderType } from "./tenderSlice";

const selectTenderState = (state: RootState) => state.tenderPage;

export function selectTenders(state: RootState) {
	const tenderState = selectTenderState(state);
	return tenderState.tenders;
}

export function selectIsTenderListLoading(state: RootState) {
	const tenderState = selectTenderState(state);
	return tenderState.loader.tender;
}

export function selectIsPaginationStopped(state: RootState) {
	const tenderState = selectTenderState(state);
	return tenderState.isPaginationStopped;
}

export const selectAreTenderPricingsLoading =
	(tenderId: number) => (state: RootState) => {
		const tenderState = selectTenderState(state);
		return tenderState.loader.pricingsByTender[tenderId] || false;
	};

export function selectIsTenderActionsLoading(state: RootState) {
	const tenderState = selectTenderState(state);
	return tenderState.loader.actions;
}

export function selectTSImporterLoader(state: RootState) {
	const tenderState = selectTenderState(state);
	return tenderState.loader.tsImporter;
}
export function selectTSImporterData(state: RootState) {
	const tenderState = selectTenderState(state);
	return tenderState.tsImporter;
}
export function selectTSImporterErrors(state: RootState) {
	const tenderState = selectTenderState(state);
	return tenderState.errors.tsImporter;
}

export function selectTSImporterWarning(state: RootState) {
	const tenderState = selectTenderState(state);
	return tenderState.warning.tsImporter;
}

export function selectWorkflowList(state: RootState) {
	const tenderState = selectTenderState(state);
	return tenderState.workflowView;
}

export const selectLoader = (key: LoaderType) => (state: RootState) => {
	const tenderState = selectTenderState(state);
	return tenderState.loader[key];
};
