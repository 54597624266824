import { Box } from "@mui/material";
import _ from "lodash";
import { FluidNumber } from "../../../../../common/components/FluidNumberField";
import { SettlementType } from "../../../../../requests_geco/contractsApi/contractsApi.types";
import { Formik } from "formik";
import { npqrsValidator } from "../../../formik/editSettlementValidationValidator";
import {
	FormikNpqrs,
	npqrsToFormik,
} from "../../../formik/settlementFormikToContractMapper";
import { ErrorText } from "../../../../../common/components/ErrorText";

export interface NpqrsFieldProps {
	settlementValues: SettlementType;
	errorMessage: string | undefined;
	name: string;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean
	) => void;
}

export function NpqrsField({
	settlementValues,
	setFieldValue,
	name,
	errorMessage,
}: NpqrsFieldProps) {
	const onSubmit = (formData: FormikNpqrs) => {
		setFieldValue(name, Object.values(formData));
	};

	return (
		<Formik
			validateOnChange={false}
			validateOnBlur={false}
			validationSchema={npqrsValidator}
			enableReinitialize
			initialValues={npqrsToFormik(settlementValues?.index?.npqrs)}
			onSubmit={onSubmit}
		>
			{({ handleChange, handleSubmit, values, errors, submitForm }) => (
				<form onSubmit={handleSubmit}>
					<Box sx={{ display: "flex", gap: "8px" }}>
						{Object.keys(values).map((field: string) => (
							<FluidNumber
								key={field}
								sx={{ width: "18%" }}
								onChange={handleChange}
								onBlur={submitForm}
								value={_.get(values, field)}
								name={field}
								title={field.toUpperCase()}
								//@ts-ignore
								errorMessage={errors[field]}
							/>
						))}
					</Box>
					<ErrorText>{errorMessage}</ErrorText>
				</form>
			)}
		</Formik>
	);
}
